<template lang="pug">
  .transactions.wrapper
    v-progress-linear(v-if="!txHistory", indeterminate, height="3", color="#19a2dc")
    div(v-else)
      .text-center(v-if="!txHistory.length") {{ $t('messages.emptyTxHistory') }}
      v-row.transactions_item(v-else, v-for="(tx, idx) in txHistory", :key="idx + tx.timestamp")
        v-col.date(:lg="3") {{ tx.timestamp * 1000 | dealDate($i18n.locale) }}
        v-col(:lg="4")
          v-layout(align-center)
            .logo
              CoinIcon(:name="tx.assetId")
            div
              div {{ tx.assetId }}
              .address(:class="{ empty: !tx.explorerUrl }")
                a(v-if="tx.explorerUrl", :href="tx.explorerUrl") {{ tx.address }}
                span(v-else) {{ tx.address }}
        v-col.text-right(:lg="3")
          .amount_usd {{ tx.txType === 'withdraw' ? '- $' : '$' }}{{ convertToUSD(tx.amount, tx.assetId) | roundedNumber(2, $i18n.locale) }}
          .amount_crypto {{ tx.txType === 'withdraw' ? '- ' : '' }} {{ tx.amount | roundedNumber(getCurrencyPecision(tx.assetId), $i18n.locale) }}
</template>

<script>
import CoinIcon from '@/components/CoinIcon';
import worker from '@/worker';
import { mapState } from 'vuex';
import { generateId, convertToUSD } from '@/utils';

export default {
  components: { CoinIcon },
  props: {
    newestFirst: { type: Boolean, default: true },
    baseCurrencyCode: { type: String, required: true, default: 'USD' }
  },
  methods: {
    getTxHistory() {
      const payload = {
        id: generateId(),
        type: 'getTxHistory'
      };

      if (this.kickExWSOpen && this.loggedIn) worker.myWorker.postMessage(payload);
    },
    convertToUSD(total, currencyCode) {
      if (!this.stats24h || !this.currencies.length) return '';

      return convertToUSD({ total, currencyCode }, this.stats24h, this.currencies);
    },
    getCurrencyPecision(currencyCode) {
      const currency = this.currencies.find(i => i.currencyCode === currencyCode);

      return currency ? currency.e : 8;
    }
  },
  computed: {
    ...mapState('user', [
      'kickExWSOpen',
      'loggedIn',
      'txHistory',
      'currencies',
      'stats24h'
    ])
  },
  watch: {
    loggedIn(val) {
      if (val) this.getTxHistory();
    }
  },
  mounted() {
    if (!this.txHistory) {
      this.getTxHistory();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .transactions {
    text-align: left;
    color: $sub-black;
    padding-top: 40px;

    &_item {
      font-weight: 600;
      align-items: center;
      margin-bottom: 15px;

      .date {
        font-size: 12px;
        color: $light-theme-text;
      }

      .logo {
        width: 40px;
        height: 40px;
        min-width: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 15px;

        & > img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      .address {
        font-size: 12px;
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $blue;

        &.empty { color: $light-theme-text; }

        a {
          color: $blue;
          text-decoration: none;
        }
      }

      .amount_usd {
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap;
      }

      .amount_crypto {
        font-size: 12px;
        color: $light-theme-text;
        white-space: nowrap;
      }
    }

    @media screen and (max-width: 760px) {
      &_item {
        .date { flex-basis: 100% !important; }

        .col:nth-child(2),
        .col:nth-child(3) {
          min-width: 50%;
        }

        .col:nth-child(3) { padding-right: 10px; }

        .address { max-width: 132px; }

        .amount_usd,
        .amount_crypto {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
